@import "../../../app/styles/mixins/fonts.scss";

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  height: 176px;
  position: relative;
  width: 172px;
}

.image {
  height: 100%;
  transition: 1s;
}

.controlls {
  display: flex;
  gap: 8px;
  position: absolute;
  right: 9px;
  top: 9px;
  transition: 1s;
}

.badge {
  background-color: rgba(254, 236, 144, 1);
  bottom: 14px;
  color: rgba(21, 23, 39, 1);
  left: 18px;
  position: absolute;
}

.edit {
  background: var(--main-color3);
  border-radius: 8px;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding: 12px;
  position: relative;
  transition: 0.3s;
  width: 100%;
  z-index: 1;
}

.button {
  path {
    stroke: #ffffff;
    transition: 0.3s;
  }

  &:hover {
    path {
      stroke: var(--main-color2);
    }
  }
}
