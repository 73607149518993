@import "../../../app/styles/mixins/fonts.scss";

.container {
  position: relative;
  width: 100%;
}

.button {
  @include ubuntu-14();
  align-items: center;
  background: #313140;
  border-radius: 8px;
  color: rgb(93, 93, 108);
  display: flex;
  height: 40px;
  padding: 12px 50px 14px 12px;
  width: 100%;
}

.color_block {
  border-radius: 8px;
  height: 24px;
  width: 100%;
}

.select_list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
  position: absolute;
  width: 100%;
}

.select_item {
  align-items: center;
  background: #313140;
  border-radius: 8px;
  display: flex;
  height: 40px;
  padding: 8px;
}

.item_button {
  border-radius: 8px;
  height: 24px;
  padding: 5px;
  width: 100%;
}
