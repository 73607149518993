.container {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  padding: 24px 31px;
}

.select {
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
}

.input {
  span {
    pointer-events: none;
  }
}

.top_content {
  display: grid;
  gap: 8px;
  grid-template-columns: 248px 192px 192px;
  width: 100%;
}

.middle_contanet {
  display: grid;
  gap: 8px;
  grid-template-columns: 248px 192px 192px;
  width: 100%;
}

.bottom_content {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 152px) 168px;
  margin-bottom: 16px;
  width: 100%;
}

.four_container {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 16px;
  position: relative;
  width: 100%;

  &::before {
    background-color: rgba(66, 66, 79, 1);
    content: "";
    height: 1px;
    position: absolute;
    top: -20px;
    width: 100%;
  }
}
