@import "../../../app/styles/mixins/fonts.scss";

.btn {
  margin-left: auto;
  margin-top: 40px;
  padding: 12px 48px;
  width: max-content;
}

.container {
  align-items: start;
  background-color: rgba(37, 37, 55, 1);
  border: 1px solid rgba(93, 93, 108, 1);
  border-radius: 16px;
  display: flex;
  gap: 15px;
  padding: 22px 53px 24px 49px;
  width: 100%;
}

.content {
  width: 100%;
}

.title {
  @include ubuntu-medium-20();
  margin-bottom: 16px;
}

.input {
  @include ubuntu-14();
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px 14px;
  width: 100%;

  &::placeholder {
    color: rgba(93, 93, 108, 1);
  }
}

.textarea {
  @include ubuntu-14();
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-top: 16px;
  min-height: 104px;
  padding: 12px 13px;
  resize: none;
  width: 100%;

  &::placeholder {
    color: rgba(93, 93, 108, 1);
  }
}

.back_button {
  padding-top: 5px;
}

.valid_error {
  @include ubuntu-12();
}

.inputs_item {
  min-height: 61px;
}

.date {
  display: flex;
  gap: 21px;
  text-wrap: nowrap;
}

.first_row {
  display: grid;
  gap: 7px;
  grid-template-columns: 1fr 2fr;
}

.second_row {
  display: grid;
  gap: 7px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 15px;
}
